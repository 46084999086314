
export default {
    name: 'IndexDiamond',
    props: {
        data: Object,
        info: Object
    },
    data() {
        return {
            rsInfo: {},
            list: []
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 设置数据
        setData() {
            const info = JSON.parse(JSON.stringify(this.info))
            this.rsInfo = info
            this.rsInfo.rs = {}
            this.list = this.rsInfo.titleList || []
            this.rsInfo.rs.visible = this.list.length
            this.setStyle()
        },
        setStyle() {
            const unit = 'px'
            let rs = this.rsInfo.rs;
            rs.modelStyle = {
                'background-color': this.rsInfo.backgroundColor || '#fff'
            }
            rs.fontStyle = {
                color: this.rsInfo.color,
                'font-size': this.rsInfo.fontSize || 12 + unit,
                'font-family': this.rsInfo.fontFamily || 'Mulish-Regular',
                'font-weight': this.rsInfo.isBold ? 'bold': 'normal'
            }
        },
        jumpLink(obj) {
            window.location.href = obj.jumpUrl
        }
    }
}
