
export default {
    name: 'IndexFlashSale',
    props: {
        data: Object,
        info: Object
    },
    data() {
        return {
            rsInfo: {},
            list: []
        }
    },
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 设置数据
        setData() {
            const info = JSON.parse(JSON.stringify(this.info))
            this.rsInfo = info
            this.rsInfo.rs = {}
            this.list = this.rsInfo.list || []
            this.rsInfo.rs.visible = this.list.length
            this.rsInfo.rs.ref = `swiper${this.rsInfo.i}`
            // swiper数据
            this.setSwiperOptions()
            this.setStyle()
        },
        // 设置swiper数据
        setSwiperOptions() {
            let rs = this.rsInfo.rs;
            rs.options = {}
            rs.options.freeMode = true
            rs.options.slidesPerView = 'auto'
        },
        // style
        setStyle() {
            if(process.client) {
                const unit = 'px'
                let rs = this.rsInfo.rs;
                let iW = window.innerWidth > 540 ? 540 : window.innerWidth
                let w = Math.ceil(iW * 0.295);
                rs.slideStyle = {
                    width: w + unit,
                }
                rs.modelStyle = {
                    'background-color': this.rsInfo.backgroundColor || '#fff'
                }
            }
        },
        // 倒计时结束
        finishTime() {
            this.$emit('emit-finish-time')
        },
        // 跳转
        jumpLink() {
            window.location.href = this.rsInfo.expand.jumpUrl
        },
        jumpProduct(obj) {
            this.$router.push({
                name: 'Product-Seo',
                params: {
                    code: obj.productCode,
                    seoUrl: obj.seoUrl
                }
            })
        }
    }
}
