
export default {
    name: 'IndexProduct',
    components: {},
    props: {
        fnApi: Object,
        data: Object,
        info: Object,
        login: Boolean,
        allData: Object,
        rec: Object
    },
    data() {
        return {
            clear: '',
            lang: {},
            rsData: {},
            rsInfo: {},
            list: [],
            target: {},           // 当前操作的商品
            sticky: {             // 筛选
                visible: false,
                top: '4.375rem',
            },
            tab: {                // tab点击
                sortColumn: '',   // sort id
                saleCount: '',    // 销量状态
                options: [],      // sort options数据
                columns: 2        // 数据排列方式
            },
            tabProduct: {         // tab商品
                visible: false,
                id: '',
                list: [],
                lineWidth: '',
                lineHeight: '',
                className: ''
            },
            filter: {             // 筛选项
                visible: false,
                num: 0,
                data: {},
                params: {}
            },
            pull: {               // 触底加载
                hasPull: false,
                hasPullRefresh: false,
                filter: {},
                total: 0,
                list: [],
            },
            page: {
                pageNum: 1,
                pageSize: 20,
                totalPage: 10,
            },
            listCompo: { // yfn-list组件配置
                options: {
                    hasBag: true,
                    hasMore: true,
                },
                page: {
                    hasMore: true,
                    totalPage: 0,
                    maxPage: 1
                },
                filter: {
                    hasFilter: false
                }
            },
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        },
        rec: {
            handler(n, o) {
                // this.setRCOrgData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        // 设置数据
        setData() {
            const info = JSON.parse(JSON.stringify(this.info))
            const data = JSON.parse(JSON.stringify(this.data.data))
            this.rsData = data
            this.rsInfo = info
            this.rsInfo.rs = {}
            this.rsInfo.rs.ref = `swiper${this.rsInfo.i}`
            this.setSwiperOptions()
            this.setTitle()
            this.setTab()
            this.setRCData()
            if(process.client) {
                this.setStyle()
            }
            if(this.rsInfo.showType == 2) {
                this.listCompo.options.hasBag = this.rsInfo.showAddCartButton
                // this.getPageAjax()
                this.setRCOrgData()
            }
        },
        setRCData() {
            let rs = this.rsInfo.rs;
            let list = this.list;
            // showType -> 1 横向展示 2 纵向展示
            // addGoodsType -> 1 手动添加商品 2 选择分组 3 系统推荐 4 销量排行榜
            // multipleTab -> true 是否tab类型商品
            if(!this.pull.hasPull) {
                if(this.rsInfo.addGoodsType != 3) {
                    list = this.rsData.productList || []
                } else {
                    list = this.rsData.recommendList || []
                }
            } else {
                list = list.concat(this.pull.list)
            }
            // 横向添加more
            let rsList = list.filter(item => {
                return !item.hasMore
            });
            // tab模式
            if(this.rsInfo.multipleTab) {
                const filterTabProduct = this.tabProduct.list.filter(p => {
                    return p.tabId == this.tabProduct.id
                })
                const firstList = filterTabProduct[0] || {};
                rsList = firstList.productList || []
            }
            if(this.rsInfo.jumpUrl && this.rsInfo.showType == 1) {
                rsList = [...rsList, ...[{hasMore: true}]]
            }

            this.list = rsList
            // 处理评分
            this.list.forEach(item => {
                item.rsScoreAverage = item.scoreAverage / 10000
            })
            // 筛选
            if(this.rsInfo.addGoodsType == 4) {
                this.listCompo.filter.hasFilter = true
            }
            rs.length = this.list.length
            rs.visible = rs.length
            this.setBestIcon()
            this.setLabel()
            this.rsInfo.addGoodsType == 3 && this.emitInitialData()
        },
        // 设置畅销榜icon
        setBestIcon() {
            if(this.rsInfo.addGoodsType == 4) {
                this.list.forEach((item, i) => {
                    if(i < 3) {
                        item.bestIconUrl = `/assets/new/icon/bestIcon-${i + 1}.png`
                    }
                })
            }
        },
        // 设置label
        setLabel() {
            // 标签处理
            this.list.forEach((item, i) => {
                let reProductLabel = []
                !item.productLabel && (item.productLabel = [])
                item.productLabel.forEach(list => {
                    if(!item.bestIconUrl) {
                        reProductLabel.push({
                            ...list,
                            style: `color: ${list.color};background: ${list.backgroundColor};`
                        })
                    }
                })
                item.reProductLabel = reProductLabel[0] && [reProductLabel[0]] || []
            })
        },
        // 设置标题
        setTitle() {
            let rs = this.rsInfo.rs;
            rs.titleIndex = ['en', 'fr', 'de', 'es'].indexOf(this.lang.langCode) || 0
        },
        // 设置tab
        setTab() {
            this.tabProduct.list = []
            if(this.rsInfo.multipleTab) {
                this.tabProduct.list = this.data?.data?.tabDataList || [];
                const firstTabDataList = this.tabProduct.list[0] || {};
                this.tabProduct.id = firstTabDataList.tabId
                // rsList = firstTabDataList.productList || []
                this.tabProduct.visible = this.tabProduct.list.length > 1
                // tab样式
                this.tabProduct.lineWidth = !this.rsInfo.selectedUnderline ? 0 : ''
                this.tabProduct.lineHeight = this.tabProduct.lineWidth
                this.tabProduct.list.forEach(p => {
                    p.props = {}
                    p.props['title-style'] = {
                        'font-size': `${this.rsInfo.titleFontSize}px`,
                        'font-family': this.rsInfo.titleFontFamily
                    }
                })
                this.tabProduct.className = `IndexProduct-tab--${this.rsInfo.tabAlign || 'center'}`
            }
        },
        // swiper
        setSwiperOptions() {
            let rs = this.rsInfo.rs;
            rs.options = {}
            // rs.options.loop = true
            rs.options.freeMode = true
            rs.options.slidesPerView = 'auto'
        },
        // style
        setStyle() {
            const unit = 'px'
            let rs = this.rsInfo.rs;
            let iW = window.innerWidth > 540 ? 540 : window.innerWidth
            let w = Math.ceil(iW * 0.295);
            rs.slideStyle = {
                width: w + unit,
            }
            rs.modelStyle = {
                'background-color': this.rsInfo.backgroundColor || '#fff'
            }
        },
        // tab
        changeTab() {
            this.setRCData()
        },
        // 收藏
        setWishAjax() { // 收藏接口
            this.$api.product.setWishStatus.http({
                spuId: this.target.id,
                status: !this.target.wishStatus
            }).then(res => {
                this.setWishStatus()
            })
        },
        setWishStatus() { // 收藏状态
            this.target.wishStatus = !this.target.wishStatus
            let list = this.list, i = this.target.i;
            this.$set(list, i, {...list[i], wishStatus: this.target.wishStatus})
        },
        handleWish(obj) { // 收藏按钮
            this.target = obj
            if(this.login) {
                this.setWishAjax()
            } else {
                window.location.href = '/login.htm'
            }
        },
        // 加购
        handleBag(obj) {
            window.bagClick({
                ...obj,
                id: obj.id || obj.spuId, 
                seoId: obj.seoUrl,
            }, () => {})
        },
        // 筛选
        setFilterData() {
            // 筛选项
            this.filter.data = this.pull.filter || {}
            this.filter.data.total = this.pull.total
        },
        changeType(e) { // 类型 tab
            this.page.pageNum = 1
            this.pull.hasPull = false
            this.getPageAjax()
        },
        handleSale() { // 销量点击
            this.page.pageNum = 1
            this.pull.hasPull = false
            if(this.tab.saleCount) {
                this.tab.saleCount = ''
            } else {
                this.tab.saleCount = `${this.tab.sortColumn ? ',' : ''}saleCount:desc`
            }
            this.getPageAjax()
        },
        handleFilter() { // 筛选-面板显示
            this.filter.visible = true
        },
        // 触底加载更多
        getPageAjax() { // 翻页接口
            this.$api.common.getRecV5Common({
                index: 1,
                pageNum: this.page.pageNum,
                pageSize: this.page.pageSize,
            },false).then(response => {
                const res = response.result || {};
                const list = res.list || [];
                this.pull.hasPull = true
                this.pull.list = list
                this.page.totalPage = res.totalPage
                this.pull.filter = res.filter || {}
                this.pull.total = res.total || 0
                this.listCompo.page.totalPage = res.totalPage
                this.setRCData()
                this.setFilterData()
            })
        },
        setRCOrgData() {
            const list = this.rec.list || [];
            this.pull.hasPull = true
            this.pull.list = list
            this.page.totalPage = this.rec.totalPage
            this.pull.filter = this.rec.filter || {}
            this.pull.total = this.rec.total || 0
            this.listCompo.page.totalPage = this.rec.totalPage
            this.setRCData()
            this.setFilterData()
        },
        emitBottomLoading(e, callBack) { // events
            if(!this.rsInfo.rs.visible || this.rsInfo.addGoodsType != 3 || this.pull.hasPullRefresh || this.rsInfo.showType != 2) {
                return true
            }
            const hasNext = this.page.totalPage > this.page.pageNum;
            if(e.isIntersecting && hasNext) {
                this.pull.hasPull = e.isIntersecting
                this.page.pageNum += 1
                this.getPageAjax()
            }
            callBack(!hasNext)
        },
        // 跳转
        jumpProduct(obj) {
            this.$router.push({
                name: 'Product-Seo',
                params: {
                    code: obj.productCode,
                    seoUrl: obj.seoUrl
                }
            })
        },
        jumpLink() {
            window.location.href = this.rsInfo.jumpUrl
        },
        // emit
        emitInitialData() { // emit-数据初始化
            // this.$emit('emit-initial-data', {
            //     list: this.list,
            //     info: this.rsInfo,
            //     data: this.rsData
            // })
        },
        emitFilter(obj) { // emit-筛选
            this.page.pageNum = 1
            this.pull.hasPull = false
            this.filter.params = obj.form.params || {}
            this.filter.num = obj.form.changeSlideNum ? 1 : 0
            // 选中数量
            let select = obj.form.options || {}
            Object.keys(select).forEach(key => {
                if(select[key]) {
                    let type = Object.prototype.toString.call(select[key])
                    if(type == '[object Array]') {
                        this.filter.num += select[key].length
                    } else {
                        this.filter.num += 1
                    }
                }
            })
            this.clear && clearTimeout(this.clear)
            this.clear = setTimeout(() => {
                this.getPageAjax()
            }, 50)
        },
        // 初始化
        initialSet() {
            this.lang = this.$storage.get('language')
        }
    }
}
