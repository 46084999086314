
export default {
    name: 'IndexHot',
    props: {
        data: Object,
        info: Object
    },
    data() {
        return {
            rsInfo: {},
            list: []
        }
    },
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 设置数据
        setData() {
            const info = JSON.parse(JSON.stringify(this.info))
            this.rsInfo = info
            this.rsInfo.rs = {}
            this.list = this.rsInfo.hotspotList || []
            this.rsInfo.rs.visible = this.list.length
            if(process.client) {
                this.setStyle()
            }
        },
        // style
        setStyle() {
            const unit = 'px', percent = '%';
            const rs = this.rsInfo.rs;
            rs.modelStyle = {
                padding: `0 ${this.rsInfo.margins || 0}${unit}`,
                'background-color': this.rsInfo.backgroundColor,
                'box-sizing': 'border-box',
                'margin': `0 ${this.rsInfo.margins || 0}${unit}`
            }
            const iW = window.innerWidth;
            const wComp = this.rsInfo.calculatedWidth;
            const compare = iW / wComp;
            this.list.forEach(item => {
                const tl = item.location.tl;
                const w = this.setNumPad(item.width * compare) + unit
                const h = this.setNumPad(item.height * compare) + unit
                const y = this.setNumPad(tl.y * compare) + unit
                const x = this.setNumPad(tl.x * compare) + unit
                const hotStyle = {
                    width: w,
                    height: h,
                    top: y,
                    left: x,
                };
                item.hotStyle = hotStyle
            })
        },
        // 保留两位小数
        setNumPad(val) {
            return +(val.toFixed(4))
        },
        // 跳转
        jumpLink(obj) {
            window.location.href = obj.jumpUrl
        }
    }
}
