
export default {
    name: 'IndexTitle',
    props: {
        data: Object,
        info: Object
    },
    data() {
        return {
            rsInfo: {},
        }
    },
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 设置数据
        setData() {
            const info = JSON.parse(JSON.stringify(this.info))
            this.rsInfo = info
        },
        templateText() {
            let lang = this.$storage.get('language')?.identify || 'en_US';
            return this.rsInfo?.languageList[lang]?.content || ''
            // if (localStorage.getItem('country')) {
            //     lang = JSON.parse(localStorage.getItem('country'))?.language;
            // }
            // return this.template?.content?.languageList[lang]?.content || '';
        },
    }
}
